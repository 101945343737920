import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import smoothscroll from 'smoothscroll-polyfill';
import VueScrollTo from 'vue-scrollto';
import VueGtag from 'vue-gtag-next';

import App from '@/App.vue';

import './assets/styles/main.scss';

// polyfills
// --------------------------------
smoothscroll.polyfill();

// app
// --------------------------------
const app = createApp(App);

const head = createHead();
app.use(head);
app.use(VueScrollTo);
app.use(VueGtag, {
  property: { id: 'G-YCE2PTLGYR' },
});

app.mount('#app');
