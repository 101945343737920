<template>
  <div class="constrain logo">
    <a class="px-2 sm:px-4">
      <Jk class="jk" />
      <Web class="web" />
    </a>
  </div>

  <div class="overlay" :style="{opacity: overlayOpacity}" />

  <Down to="#text" />

  <ChristmasCard
    :style="{'transform': 'scale(' + cardScale + ')'}"
  />

  <div class="scroll-wrapper">
    <div id="text" class="card-text">
      <h1>Wir sind in Feierlaune</h1>
      <!-- eslint-disable -->
      <p>
        Dieses Jahr war ein ganz spezielles für jkweb. Zu unserem 10-jährigen Bestehen haben wir unseren eigenen Auftritt komplett frisch gedacht. Das Resultat widerspiegelt nun endlich wieder die Qualität der Arbeit, die unsere mittlerweile 37 Mitarbeitenden tagtäglich abliefern.
      </p>
      <p>
        Von ganzem Herzen bedanken wir uns für die tolle Zusammenarbeit im vergangenen Jahr und schauen glücklich zurück auf die vielen tollen Projekte, die wir gemeinsam umsetzen konnten. Wir wünschen dir frohe Festtage und können es selbst kaum erwarten, voller Energie ins nächte Jahr und in neue Projekte zu starten.
      </p>
      <JkButton to="https://jkweb.ch">
        zur neuen Webseite
      </JkButton>
    </div>
  </div>
</template>

<script>

import { useGtag } from 'vue-gtag-next';
import ChristmasCard from '@/components/ChristmasCard.vue';

import Jk from '@/assets/images/jk.svg';
import Web from '@/assets/images/web.svg';
import JkButton from '@/components/JkButton.vue';
import Down from '@/components/Down.vue';

export default {
  components: {
    Down,
    JkButton,
    ChristmasCard,
    Jk,
    Web,
  },
  setup() {

  },
  data() {
    return {
      overlayOpacity: 0,
      cardScale: 1,
    };
  },
  computed: {},

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    const { pageview } = useGtag();
    if (pageview) pageview({ page_path: '/', page_title: 'Front page' });
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll(e) {
      const SCROLL_THRESHOLD = 400;
      const MAX_OPACITY = 0.7;
      const MIN_SCALE = 0.8;
      const EASE = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

      const progress = EASE(Math.min(window.scrollY / SCROLL_THRESHOLD, 1));

      this.overlayOpacity = (progress * MAX_OPACITY);
      this.cardScale = ((1 - progress) * (1 - MIN_SCALE)) + MIN_SCALE;
    },
  },

};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  z-index: 1;
  pointer-events: none;
}

.scroll-wrapper {
  padding-top: calc(100vh);
  position: relative;
  z-index: 2;
  pointer-events: none;

  .card-text {
    max-width: max(50vw, 30rem);
    margin: auto;
    padding: 2rem;
    padding-bottom: 5rem;
    pointer-events: all;

    a {
      pointer-events: all;
    }
  }
}

.logo {
  position: relative;
  width: 100%;
  a {
    display: flex;
    flex-direction: column;
  }
  .jk {
    position: fixed;
    z-index: 1000;
    top: px(52);
    width: px(52);
  }
  .web {
    position: fixed;
    z-index: 1000;
    bottom: px(52);
    width: px(115);
    align-self: flex-end;
  }
  .beta {
    position: fixed;
    z-index: 1000;
    top: px(80);
    margin-left: px(55);
    font-weight: bold;
    font-size: 0.8em;
  }

  @media (max-width: theme("screens.md")) {
    .jk {
      top: px(40);
      width: px(40);
    }
    .beta {
      top: px(59);
      margin-left: px(45);
    }
    .web {
      bottom: px(20);
      width: px(85);
    }
  }
}

</style>
