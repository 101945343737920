<template>
  <div class="wrapper" :style="style">
    <atropos
      :active-offset="40"
      :shadow-scale="1.05"
      events-el="body"
      :common-origin="false"
    >
      <div class="card-wrapper">
        <ar-button
          ios-src="https://weihnachten2021.jkweb.ch/models/weihnachtskarte2021.usdz"
          src="https://weihnachten2021.jkweb.ch/models/weihnachtskarte2021.glb"
        >
          <img
            src="@/assets/images/ar.png"
            alt="AR"
            class="badge"
            data-atropos-offset="0"
          >
        </ar-button>
        <div class="card" data-atropos-offset="1">
          <div data-atropos-offset="10" class="kugel">
            <Kugel />
          </div>
          <div data-atropos-offset="5" class="santa">
            <Santa />
          </div>
          <div data-atropos-offset="30" class="socke">
            <Socke />
          </div>
          <div data-atropos-offset="15" class="stern">
            <Stern />
          </div>
          <div data-atropos-offset="20" class="tanne">
            <Tanne />
          </div>
          <div data-atropos-offset="20" class="zweig">
            <Zweig />
          </div>
        </div>
      </div>
    </atropos>
  </div>
</template>

<script>

import Atropos from 'atropos/vue/atropos-vue.esm';
import Kugel from '@/assets/images/kugel.svg';
import Santa from '@/assets/images/santa.svg';
import Socke from '@/assets/images/socke.svg';
import Stern from '@/assets/images/stern.svg';
import Tanne from '@/assets/images/tanne.svg';
import Zweig from '@/assets/images/zweig.svg';
import Ar from '@/assets/images/ar.svg';
import ArButton from '@/components/ArButton.vue';

export default {
  components: {
    Atropos,
    Kugel,
    Santa,
    Socke,
    Stern,
    Tanne,
    Zweig,
    ArButton,
    Ar,
  },

  props: {
    style: {
      type: Object,
      default: null,
    },
  },

  setup() {

  },

  computed: {
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 0;

  /* https://www.arungudelli.com/tutorial/css/disable-text-selection-in-html-using-user-select-css-property/ */
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  --padding: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 100vh;
  padding: var(--padding);

  @media (max-width: theme("screens.sm")) {
    --padding: 3rem;
    height: calc(100vh - 100px);
    min-height: calc(100vh - 100px);
  }

  .card-wrapper {
    max-width: 50vh;
    width: 300px;
    @screen sm {
      width: 400px;
    }
    @screen md {
      width: 600px;
    }
    @screen lg {
      width: 800px;
    }
    padding-bottom: 141%;
    position: relative;
  }
  .card {
    width: 100%;
    height: 100%;
    background: white;
    overflow: hidden;
    position: absolute;
  }

  .badge {
    $badge: 30%;
    position: absolute;
    right: -$badge/2;
    width: $badge;
    z-index: 200;
    transform: translateZ(1px) translateY(-50%) !important;
    opacity: 0.9;
  }
  [ar="unsupported"] {
    cursor: default;
    .badge {
      display: none;
    }
  }

  > div {
    will-change: transform;

    svg {
      width: 100%;
      height: 100%;
      opacity: 0.9;
      // mix-blend-mode: multiply;
    }
  }

  .kugel {
    position: absolute;
    top: 16.65%; // 34.978 / 210
    left: 74.47%; // 110.228 / 148
    width: 30.19%; // 44.688 / 148
  }

  .santa {
    position: absolute;
    top: -11.74%; // -24.667 / 210
    left: -21.62%; // -32 / 148
    width: 56.47%; // 83.58 / 148
    height: 57.96%; // 83.58 / 210
  }

  .socke {
    position: absolute;
    top: 6.1%; // 12.843 / 210
    left: 16.63%; // 24.621 / 148
    width: 66.43%; // 98.322 / 148
    height: 81.57%; // 171.314 / 210
    z-index: 100;
  }

  .stern {
    position: absolute;
    top: 41.48%; // 87.12 / 210
    left: 41.71%; // 61.733 / 148
    width: 74.63%; // 110.467 / 148
    height: 59.77%; // 125.536 / 210
  }

  .tanne {
    position: absolute;
    left: -18.24%; // -27 / 148
    top: 35.03%; // 73.571 / 210
    width: 84.96%; // 125.75 / 148
    height: 70.77%; // 148.629 / 210
  }

  .zweig {
    position: absolute;
    top: -25.93%; // -54.461 / 210
    left: 49.23%; // 72.874 / 148
    width: 63.88%; // 94.557 / 148
  }
}

</style>
