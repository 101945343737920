<template>
  <a ref="button">
    <slot />
  </a>
</template>

<script>
const IS_ANDROID = /android/i.test(navigator.userAgent);
const IS_IOS = (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream)
  || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

const IS_SAFARI = /Safari\//.test(navigator.userAgent);
const IS_FIREFOX = /firefox/i.test(navigator.userAgent);
const IS_OCULUS = /OculusBrowser/.test(navigator.userAgent);
const IS_IOS_CHROME = IS_IOS && /CriOS\//.test(navigator.userAgent);
const IS_IOS_SAFARI = IS_IOS && IS_SAFARI;

const SUPPORTS_SCENEVIEWER = IS_ANDROID && !IS_FIREFOX && !IS_OCULUS;
const SUPPORTS_QUICKLOOK = (() => {
  const anchor = document.createElement('a');
  return anchor.relList && anchor.relList.supports && anchor.relList.supports('ar');
})();

export default {
  components: {},

  props: {
    fallbackUrl: { type: String, default: null },
    iosSrc: { type: String, default: null },
    src: { type: String, default: null },
  },

  setup() {

  },

  data() {
    return {
    };
  },

  computed: {},
  mounted() {
    const { button } = this.$refs;

    if ((IS_IOS_CHROME || IS_IOS_SAFARI) && SUPPORTS_QUICKLOOK) {
      // system supports AR via quick look (on ios this takes precedence on scene viewer)
      button.setAttribute('ar', 'quick-look');
      button.dispatchEvent(new CustomEvent('initialized', { detail: 'quick-look' }));
      button.addEventListener('click', () => {
        if (!this.iosSrc) {
          console.error(`Invalid ios-src in <ar-button>: ${button}`);
          return;
        }

        const href = `${this.iosSrc}#`;
        this.activateAR(href, button, true);
      });
    } else if (SUPPORTS_SCENEVIEWER) {
      // system supports AR via scene viewer
      button.setAttribute('ar', 'scene-viewer');
      button.dispatchEvent(new CustomEvent('initialized', { detail: 'scene-viewer' }));
      button.addEventListener('click', () => {
        if (!this.src) {
          console.error(`Invalid src in <ar-button>: ${button}`);
          return;
        }

        const fallbackUrl = button.getAttribute('fallback-url');

        let href = `intent://arvr.google.com/scene-viewer/1.0?file=${this.src}&mode=ar_only`;

        href
          += '#Intent;scheme=https;'
          + 'package=com.google.ar.core;'
          + 'action=android.intent.action.VIEW;';
        if (this.fallbackUrl) {
          href += `S.browser_fallback_url=${encodeURIComponent(this.fallbackUrl)};`;
        }
        href += 'end;';

        this.activateAR(href);
      });
    } else {
      // No AR supported on current system, hide the button or sets a fallback url
      button.setAttribute('ar', 'unsupported');
      button.dispatchEvent(new CustomEvent('initialized', { detail: 'unsupported' }));
      if (this.fallbackUrl !== null) {
        button.addEventListener('click', () => {
          // activateAR(encodeURIComponent(fallbackUrl));
        });
      }
    }
  },

  methods: {
    activateAR(href, button, isQuickLook) {
      const anchor = document.createElement('a');
      if (isQuickLook) {
        // quick look needs a <img> child to go directly to AR view
        anchor.appendChild(document.createElement('img'));
        anchor.rel = 'ar';
      }
      anchor.setAttribute('href', href);
      anchor.click();
      if (button && isQuickLook) {
        anchor.addEventListener(
          'message',
          (event) => {
            if (event.data === '_apple_ar_quicklook_button_tapped') {
              button.dispatchEvent(new CustomEvent('quick-look-button-tapped'));
            }
          },
          false,
        );
      }
    },
  },

};
</script>

<style lang="scss" scoped>

</style>
