<template>
  <Go :to="to" class="inline-block button" :class="[type]">
    <LongArrow class="arrow" />
    <span class="slot"><slot /></span>
  </Go>
</template>

<script>
import LongArrow from '@/assets/images/long_arrow.svg';
import Go from '@/components/Go.vue';

export default {
  components: { Go, LongArrow },
  props: {
    to: { type: String, default: null },
    type: { type: String, default: 'right' },
  },
};
</script>

<style lang="scss" scoped>
.button {
  position: relative;
  overflow: hidden;
  padding-top: px(30);
  padding-left: 2em;
  padding-right: 2em;

  @screen md {
    padding-right: 2em;
  }

  .arrow {
    position: absolute;
    right: calc(100% - 1.3em);
    bottom: 0.2em;
    transition: right 0.25s ease-in-out;
    height: 1em;
    width: auto;
  }

  .slot {
    display: inline-block;
    transition: transform 0.25s ease-in-out;
    transition-delay: 0.15s;
  }

  @include hover {
    .arrow {
      right: 0;
      transition-delay: 0.2s;
    }
    .slot {
      transform: translateY(-.7em);
      transition-delay: 0;
    }
  }
}

.button.left {
  padding-left: 2.7em;
  padding-right: 2em;

  .arrow {
    right: auto;
    transition: left 0.25s ease-in-out;
    left: calc(100% - 1.2em);
    transform: rotate(180deg);
  }

  @include hover {
    .arrow {
      right: auto;
      left: 0;
      transition-delay: 0.2s;
    }
  }
}

.button.diogonal {
  .arrow {
    bottom: 0.5em;
    right: calc(100% - 0.8em);
    transform: rotate(-45deg);
    transform-origin: top right;
    transition: transform 0.25s ease-in-out;
  }
  .slot {
    transition-delay: 0s;
  }

  @include hover {
    .arrow {
      transform: rotate(-45deg) translateX(px(23));
    }
    .slot {
      transform: translateX(px(8));
    }
  }
}
</style>
